import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/de"
import Seo from "../../components/seo"
import SimplePage from "../../templates/simple-page"
import Features from "../../components/features"
import Link from "../../components/link"
import BackLink from "../../components/back-link"
import Download from "../../components/download"

const DeliveryProgramPage = () => {
  const { fileNode, image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { glob: "delivery-program-wire/*.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNode: sanityDownload(
          file: {de: {fileName: {eq: "Finkernagel Lieferprogramm Draht"}}}
        ) {
          id
          title {
            de
          }
          file {
            de {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Lieferprogramm Draht" />
      <SimplePage title="Lieferprogramm Draht" prefix={<BackLink to="/wire" />}>
      <Download extension={fileNode.file.de.file.asset.extension}
        fileName={`/static/${fileNode.file.de.fileName}.${fileNode.file.de.file.asset.extension}`} 
        key={fileNode.id} 
        title="Lieferprogramm herunterladen"
        size={fileNode.file.de.file.asset.size}/>

        <h2 className="text-black">Lieferformen</h2>
        <Features
          items={[
            "Kronenständer von Ø 1,00 mm – 10,00 mm bis 1.000 kg",
            "Ringe von Ø 1,00 mm – 33,00 mm bis 3.000 kg",
            "Spulen von Ø 2,00 mm – 16,00 mm bis 3.000 kg",
          ]}
        />

        <h2 className="text-black">Werkstoffe</h2>
        <Features
          items={[
            "Kaltstauchdraht für Schrauben, Muttern und Kaltfließpressteile gemäß DIN EN 10263, Teil 1 bis 4",
            "Cr-, CrB-, CrMo- oder CrNiMo-legierte Werkstoffe",
            "Warmfester Kaltstauchdraht, z. B. 21CrMoV5-7",
            "Profildraht (Sechskant, Vierkant, Zeichnungsprofile)",
            "Wälzlagerstähle gemäß DIN EN ISO 683-17, z. B. D85, 100Cr6",
            "Werkzeug- und Federstähle, z. B. 50CrV4, 61CrSiV5",
            "Unlegierte Vergütungsstähle, z. B. C60E, C85",
            "Sonderwerkstoffe",
          ]}
        />

        <h2 className="text-black">Oberflächen</h2>
        <Features
          items={[
            "Zinkphosphatiert",
            "Zinkphosphatiert und reaktiv beseift",
            "Zinkphosphatfrei",
            "Blank",
            "Beseift",
            "Polymerbeschichtet",
          ]}
        />

        <p>
          <Link to="/contact#sales-wire" className="accessory">
            Kontakt aufnehmen
          </Link>
        </p>

        {image && (
          <figure className="-mb-2 mt-12">
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
          </figure>
        )}
      </SimplePage>
    </Layout>
  )
}

export default DeliveryProgramPage
